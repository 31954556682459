import './App.css';
import React, { useEffect, useState } from "react";
import { nft } from "./constants/contracts";
import Onboard from 'bnc-onboard'
import Notify from 'bnc-notify'
import Web3 from 'web3'
import { whitelist } from "./constants/whitelist";
import { ethers } from 'ethers';

function App() {
  // const NETWORK_ID = 4;
  // const NETWORK_NAME = 'rinkeby';
  const NETWORK_ID = 1;
  const NETWORK_NAME = 'mainnet';
  const CONTRACT_ADDRESS = '0x0a8606080a0004144B0BDc9C6abF36F82b760161';//0x7b6412a16d6f4761942E9B190A8084FEDef7E136'
  const INFURA_KEY = '944806d113814fc9bcf9b86d32e48ce7';//'d50959c651494f2197e6268bf2965011'
  const BLOCKNATIVE_KEY = 'cf52489b-d2cc-4aa1-ba6b-1d20adc79295'
  const RPC_URL = 'https://' + NETWORK_NAME + '.infura.io/v3/' + INFURA_KEY
  const [web3, setWeb3] = useState(null)
  const [onboard, setOnboard] = useState(null)
  const [address, setAddress] = useState(null)
  const [addressLC, setAddressLC] = useState(null)
  const [tokenStatus, setTokenStatus] = useState(null)
  const [contract, setContract] = useState(null)
  const [counter, setCounter] = useState(1);
  const [mintPrice, setMintPrice] = useState(null);
  const [publicPrice, setPublicPrice] = useState(null);
  const [vipPrice, setVipPrice] = useState(null);

  const notify = Notify({
    dappId: BLOCKNATIVE_KEY,
    networkId: NETWORK_ID,
    darkMode: true
  });

  useEffect(() => {
    const onboard = Onboard({
      dappId: BLOCKNATIVE_KEY,
      networkId: NETWORK_ID,
      walletSelect: {
        wallets: [
          { walletName: "metamask" },
          { walletName: "coinbase" },
          { walletName: "walletConnect", infuraKey: INFURA_KEY },
          {
            walletName: 'walletLink',
            rpcUrl: RPC_URL, // url to connect to an RPC endpoint (ie infura)
            appName: "Meta3oys",
            appLogoUrl: 'https://meta3oys.com/favicon.ico',
          }
        ],
      },
      subscriptions: {
        address: setAddressLC,
        wallet: wallet => {
          if (!wallet.provider) return
          setWeb3(new Web3(wallet.provider))
          // instantiate web3 when the user has selected a wallet
          localStorage.setItem('selectedWallet', wallet.name)
          console.log(`${wallet.name} connected!`)
        }
      }
    })
    setOnboard(onboard)
  }, [])

  useEffect(async () => {
    const previouslySelectedWallet = localStorage.getItem('selectedWallet')
    if (previouslySelectedWallet && onboard) {
      console.log('previously selected wallet', previouslySelectedWallet)
      const walletSelected = await onboard.walletSelect(previouslySelectedWallet)
      if (!walletSelected) return false
    }
  }, [onboard])

  useEffect(async () => {
    if (addressLC) {
      await onboard.walletCheck()
      setAddress((await web3.eth.getAccounts())[0])
    }
  }, [addressLC])

  useEffect(async () => {
    if (web3) {
      setContract(new web3.eth.Contract(nft, CONTRACT_ADDRESS))
    }
  }, [web3])

  useEffect(async () => {
    if (contract) {
      setTokenStatus(await contract.methods.getTokenStatus().call())
    }
  }, [contract])

  const ConnectWallet = async () => {
    const walletSelected = await onboard.walletSelect()
    if (!walletSelected) {
      return false
    }
    await onboard.walletCheck()
  };
  useEffect(async () => {
    if (contract) {
      let price;
      price = await contract.methods.getVipPrice().call();
      setVipPrice(ethers.utils.formatEther(price));
    }
  })
  useEffect(async () => {
    if (contract) {
      let price;
      price = await contract.methods.getPrice().call();
      setPublicPrice(ethers.utils.formatEther(price));
    }
  }, [contract])
  useEffect(async () => {
    if (address) {
      let price;
      let hash = whitelist[address.toUpperCase()];
      console.log('hash: ', hash);
      if (hash) {
        price = await contract.methods.getVipPrice().call();
        setMintPrice(ethers.utils.formatEther(price));
      } else {
        price = await contract.methods.getPrice().call();
        setMintPrice(ethers.utils.formatEther(price));
      }
    }
  }, [address])

  const Mint = async () => {
    let dismissPending, hash, contractMethod, price;
    console.log('token status is', tokenStatus);
    if (!address) {
      notify.notification({
        eventCode: "failed",
        type: "hint",
        message: 'Please, connect wallet first!'
      })
      return;
    }
    hash = whitelist[address.toUpperCase()];
    switch (tokenStatus) {
      case 'VipMinting':
        if (!hash) {
          notify.notification({
            eventCode: "failed",
            type: "error",
            message: 'Your wallet address is not on the whitelist!'
          })
          return;
        }
        contractMethod = contract.methods.mintVip(counter, hash);
        price = await contract.methods.getVipPrice().call()
        break;
      case 'Minting':
        if (hash) {
          contractMethod = contract.methods.mintVip(counter, hash);
          price = await contract.methods.getVipPrice().call()
          break;
        } else {
          contractMethod = contract.methods.mint(counter);
          price = await contract.methods.getPrice().call()
          break;
        }
      case 'Paused':
        notify.notification({
          eventCode: "paused",
          type: "hint",
          message: "Mint is paused!"
        })
        return
      case 'Sale closed':
        notify.notification({
          eventCode: "closed",
          type: "hint",
          message: "Mint is closed!"
        })
        return
      default:
        notify.notification({
          eventCode: "closed",
          type: "hint",
          message: "Mint is finished! (" + tokenStatus + ")"
        })
        return
    }

    const gasAmount = await contractMethod.estimateGas({ from: address, value: price * counter })
      .catch((error) => {
        notify.notification({
          eventCode: "failed",
          type: "error",
          message: error.code ? error.message : error.message.substr(0, error.message.indexOf('{') - 1)
        })
      })
    if (!gasAmount) {
      return
    }
    const gasPrice = await web3.eth.getGasPrice()
    const commission = gasAmount * gasPrice * 100 / price / counter
    console.log('commission % is', commission)
    if (commission > 50) {
      notify.notification({
        eventCode: "gas",
        type: "error",
        message: "Gas price is more than 50%, try later"
      })
      return
    }
    contractMethod.send({ from: address, value: price * counter, gas: gasAmount })
      .on('transactionHash', (hash) => {
        const { dismiss } = notify.notification({
          eventCode: "started",
          type: "pending",
          message: "Your transaction has started"
        })
        dismissPending = dismiss;
        console.log('hash', hash)
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        console.log('confirmation', confirmationNumber, receipt)
      })
      .on('receipt', (receipt) => {
        dismissPending()
        notify.notification({
          eventCode: "succeeded",
          type: "success",
          message: "Your transaction has succeeded"
        })
        console.log('receipt', receipt)
      })
      .on('error', function (error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
        if (dismissPending) dismissPending()
        if (4001 === error.code) {
          notify.notification({
            eventCode: "rejected",
            type: "hint",
            message: "You rejected transaction"
          })
          return;
        }
        notify.notification({
          eventCode: "failed",
          type: "error",
          message: "Your transaction has failed"
        })
        console.log(error)
      });
  };

  function subscribe() {
    window.subscribersSiteId = '82d932fd-741e-4635-ad2e-9e2ba94c39a6';
    //6afaaee3-c1fe-40d2-b2ad-8c66c3862c08 - discord free pt test
    const script = document.createElement('script');
    script.src = 'https://cdn.subscribers.com/assets/subscribers.js';
    document.body.appendChild(script);
  }

  const incrementCounter = () => { if (counter < 5) setCounter(counter + 1); }
  const decrementCounter = () => { if (counter > 1) setCounter(counter - 1); }

  var countDownDate = new Date("Feb 16, 2022 21:00:00 UTC+1").getTime();
  var x = setInterval(function () {
    var now = new Date().getTime();
    var distance = countDownDate - now;
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    document.getElementById("hours").innerHTML = hours;
    document.getElementById("min").innerHTML = minutes;
    document.getElementById("sec").innerHTML = seconds;

    if (distance < 0) {
      clearInterval(x);
      document.getElementById("demo").innerHTML = "EXPIRED";
    }
  }, 1000);

  return (
    <div className="s-background">
      <a className='back-link' href='https://meta3oys.com' target='_blank' rel='noreferrer'>
        <div className='mint-btn'>
          <div className='name-wrapper'>
            <span className='back-link-text'>Back to META3OYS.com</span>
          </div>
        </div>
      </a>
      <div className='container'>

        <div className='mint-box'>

          <div className='connect-wallet'>
            <div className='mint-btn' onClick={() => ConnectWallet()}>
              <div className='name-wrapper sized-btn'>
                <span className='white-border'>{address ? `${address.slice(0, 10)}...${address.slice(address.length - 6, address.length)}`
                  : '👉CONNECT WALLET'}</span>
              </div>
            </div>
          </div>
          <div className='mint-text'>GET YOUR META3OYS</div>
          <div className='mint-container white-border'>
            <div className='counter-item' onClick={decrementCounter}>-</div>
            <div className='counter-number'>{counter}</div>
            <div className='counter-item' onClick={incrementCounter}>+</div>
            <div className='mint-btn' onClick={() => Mint()}>
              <div className='name-wrapper-mint name-wrapper'>
                <span>Mint👈</span>
              </div>
            </div>
          </div>
          {mintPrice ?
            <div className='mint-price'>
              1 META3OY = {mintPrice} <img className='eth-icon' src='https://ethereum.org/static/a110735dade3f354a46fc2446cd52476/f3a29/eth-home-icon.webp' width="10" /> + GAS
            </div>
            :
            <div className='mint-price'>
              1 META3OY = {publicPrice} <img className='eth-icon' src='https://ethereum.org/static/a110735dade3f354a46fc2446cd52476/f3a29/eth-home-icon.webp' width="10" /> or {vipPrice} <img className='eth-icon' src='https://ethereum.org/static/a110735dade3f354a46fc2446cd52476/f3a29/eth-home-icon.webp' width="10" /> (WL) + GAS
            </div>
          }
        </div>
      </div>

    </div>
  );
}

export default App;
